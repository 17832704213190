<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 80px;">

			<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-20 mt-20">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						<a-col :span="24">
							<h3 class="mb-0 mt-10 text-center">Recover Password</h3>
							<p class="text-muted text-center mt-0 pt-0">Follow the steps to create new password</p>
						</a-col>
					</a-row>

					<a-row :gutter="[24,24]" class="mt-25">
						<a-col :span="24">
							<a-steps :current="current">
								<a-step v-for="item in steps" :key="item.title" :title="item.title" />
							</a-steps>
							<div class="steps-content">
								<!-- {{ steps[current].content }} -->
								<template>
									<a-row ty :gutter="[24,24]">
										
										<a-col :span="24" :md="24" :lg="24">

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==0">
												<a-col :span="24" :md="16" :lg="16" :xl="{span: 16, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 16px;"><strong>Enter your registered email address or phone number</strong></label>
														</a-col>
													</a-row>

													<a-alert
														v-if="error.show"
														:message="error.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form
														id="components-form-demo-normal-login"
														:form="form"
														class="login-form"
														@submit="handleSubmitUsername"
														:hideRequiredMark="true">
														<a-input-group>
															<a-row :gutter="24">
																<a-col :span="5" class="w-100 pr-0 mr-0">
																	<a-form-item class="mb-10 w-100" label=" " :colon="false">
																		<vue-country-code
																			style="height: 40px; width: 100%; margin-top: 4px;"
																			@onSelect="onSelectCountry"
																			:preferredCountries="['tz', 'ke', 'ug', 'za']"
																			defaultCountry="tz">
																		</vue-country-code>
																	</a-form-item>
																</a-col>
																<a-col :span="19" class="ml-0 pl-1">
																	<a-form-item class="mb-10" :colon="false">
																		<a-input 
																			style="margin-top: 32px; width: 100%;"
																			v-decorator="[
																			'username',
																			{ rules: [{ required: true, message: 'Please input your username!' }] },
																			]" placeholder="Email or Phone number" />
																	</a-form-item>
																</a-col>
															</a-row>
															
														</a-input-group>
														

														<a-form-item class="mt-2 text-center mt-5 pt-5">
															<a-button id="submitUsernameBtn" :loading="isLoading" type="silabu" style="width: 200px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																RECOVER
															</a-button>
														</a-form-item>
														
													</a-form>
												</a-col>
											</a-row>

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==1">
												<a-col :span="24" :md="13" :lg="13" :xl="{span: 13, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 16px;"><strong>Enter your verification code we sent to your {{ username != null ? username.includes('@') ?  'email address' : 'phone number' : 'username'}}</strong></label>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorToken.show"
														:message="errorToken.message"
														type="error"
														closable
														style="margin-top: 20px !important;"
														:after-close="handleCloseToken"
														show-icon 
													/>

													<a-row :gutter="24" class="mb-5 pb-5">
														<a-col :span="24" class="ml-0 pl-1">
															<a-input 
																style="margin-top: 32px; width: 100%;"
																v-model="resetPassword.token"
																type="number"
																v-decorator="[ 'token',
																{ rules: [{ required: true, message: 'Please input verification code!' }] },
																]" 
																placeholder="Enter verification code" />
														</a-col>
													</a-row>

													<a-row>
														<a-col :span="12" class="text-left mt-2 mt-5 pt-5">
															<a-button id="backBtn" @click="previousScreen" style="width: 100px !important;" block class="login-form-button mt-10 text-white" >
																Back
															</a-button>
														</a-col>
														<a-col :span="12" class="text-right">
															<a-button id="submitTokenBtn" @click="onSubmitToken" :loading="isLoading" type="silabu" style="width: 150px !important;margin-top: 20px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																RECOVER
															</a-button>
														</a-col>
													</a-row>
														
												</a-col>
											</a-row>

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==2">
												<a-col :span="24" :md="16" :lg="16" :xl="{span: 16, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center mb-5 pb-5">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 16px;"><strong>Your password should have atleast 8 characters</strong></label>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorPassword.show"
														:message="errorPassword.message"
														type="error"
														closable
														class="mt-5"
														style="margin-top: 20px !important;"
														:after-close="handleClosePassword"
														show-icon 
													/>

													<a-form
														id="components-form-demo-normal-login"
														:form="formPassword"
														class="login-form"
														style="margin-top: 20px !important;"
														@submit="handleSubmitPassword"
														:hideRequiredMark="true">
														

														<a-form-item class="mb-5" label="Password" :colon="false">
															<a-input
																v-decorator="['password',
																	{ rules: [
																		{ required: true, message: 'Please input your password!' },
																		{ min: 8, message: 'Password should have atleast 8 characters!' }
																	] 
																},]" 
																type="password" placeholder="Password" />
														</a-form-item>


														<a-form-item class="mb-5" label="Confirm Password" :colon="false">
															<a-input
																v-decorator="['passwordConfirmation',
																	{ rules: [
																		{ required: true, message: 'Please input your password again!' },
																		{ min: 8, message: 'Password should have atleast 8 characters!' }
																	] 
																},]" 
																type="password" placeholder="Confirm Password" />
														</a-form-item>

														<a-row>
															<a-col :span="12" class="text-left mt-2 mt-5 pt-5">
																<a-button id="backBtn" @click="previousScreen" style="width: 100px !important;" block class="login-form-button mt-10 text-white" >
																	Back
																</a-button>
															</a-col>
															<a-col :span="12" class="text-right">
																<a-form-item class="mt-2 mt-5 pt-5">
																	<a-button id="submitUsernameBtn" :loading="isLoadingPassword" type="silabu" style="width: 150px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																		Change Password
																	</a-button>
																</a-form-item>
															</a-col>
														</a-row>
														

														
														
													</a-form>
												</a-col>
											</a-row>
														
										</a-col>
									</a-row>
								</template>
							</div>
							<div class="steps-action">
								<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
								<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->
							</div>
						</a-col>
					</a-row>

					
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
	import VueCountryCode from "vue-country-code-select";
    import { notification } from 'ant-design-vue';

	export default ({
		components: {
			VueCountryCode
		},

		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				// form: null,
				// formPassword: null,
				
				error: {
					message: null,
					show: false,
				},

				errorToken: {
					message: null,
					show: false,
				},

				errorPassword: {
					message: null,
					show: false,
				},

				resetPassword: {
					token: null,
					password: null,
					passwordConfirmation: null,
				},

				username: null,

				countryCode: '255',

				isLoading: false,
				isLoadingToken: false,
				isLoadingPassword: false,

				current: 0,
				steps: [
					{
						title: 'Username',
						content: 'First-content',
					},
					{
						title: 'Verification Code',
						content: 'Second-content',
					},
					{
						title: 'New Password',
						content: 'Last-content',
					},
				],
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
			this.formPassword = this.$form.createForm(this, { name: 'normal_login' });
		},
		created() {
		},
		methods: {

			next() {
				this.current++;
			},

			previousScreen() {
				if(this.current > 0) {
					this.current--;
				}
			},

			onSubmitToken() {
				this.isLoadingToken = true;
				this.errorToken.message = null;
				this.errorToken.show = false;

				if(this.resetPassword.token != null && this.resetPassword.token.length == 6) {
					this.current++;
				}else{
					this.errorToken.message = "Invalid token";
					this.errorToken.show = true
				}
			},

			onSelectCountry({name, iso2, dialCode}) {
				this.countryCode = dialCode;
			},

			handleClose() {
				this.error.show = false;
			},

			handleCloseToken() {
				this.errorToken.show = false;
			},

			handleClosePassword() {
				this.errorPassword.show = false;
			},

			// Handles input validation after submission. handleSubmitNewPassword
			handleSubmitUsername(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.error.show = false;
						this.error.message = null;
						this.isLoading = true;

						const constUsername = values.username;
						let username = '';

						if(!constUsername.includes("@")){

							if(constUsername.substring(0, 4).includes(this.countryCode) && !constUsername.includes("+")) {
								username = "+" + constUsername;
							}

							else if(constUsername.substring(0, 4).includes(this.countryCode) && constUsername.includes("+")) {
								username =  constUsername;
							}

							else if(constUsername[0] == "0" || constUsername[0] == 0) {
								username = "+" + this.countryCode + "" + constUsername.substring(1)
							}

							else if(constUsername[0] != "0" || constUsername[0] != 0) {
								username = "+" + this.countryCode + "" + constUsername
							}

							else {
								username = constUsername;
							}

						}else{
							username = constUsername;
						}

						let url = `${this.$BACKEND_URL}/auth/forgot-password`;
						
						this.$AXIOS.post(url, { username }).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {
								
								this.username = username;

								this.current++;

								this.notify(`Verification token has been sent to your ${username.includes('@') ? 'email address' : 'phone number'}`, 'success')
								

							}
							this.isLoading = false
						}).catch((err) => {
							console.log(err)
							this.isLoading = false;
							
							this.error.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
							this.error.show = true
						});
					}
				});
			},


			handleSubmitPassword(e) {
				e.preventDefault();
				this.formPassword.validateFields((err, values) => {
					if ( !err ) {
						this.errorPassword.show = false;
						this.errorPassword.message = null;
						this.isLoadingPassword = true;

						const password = values.password;
						const passwordConfirmation = values.passwordConfirmation;
						const token = this.resetPassword.token;

						console.log(password)
						console.log(passwordConfirmation)

						if(password != passwordConfirmation){

							this.errorPassword.message = "The two passwords do not match";
							this.isLoadingPassword = false
							this.errorPassword.show = true

						}else{
					
							let url = `${this.$BACKEND_URL}/auth/reset-password`;
							
							this.$AXIOS.post(url, { password, passwordConfirmation, token}).then(async(response) => {
								if (response.status >= 200 && response.status < 210) {

									this.notify(`Password reset was successfully`, 'success')
									
									this.isLoading = false;

									this.$router.push(`/sign-in`);

								}
								this.isLoading = false
							}).catch((err) => {
								console.log(err)
								this.isLoadingPassword = false;
								
								this.errorPassword.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
								this.errorPassword.show = true
							});
						}
					}
				});
			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },
		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	.ant-steps-item-process .ant-steps-item-icon { background: red; }
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#submitUsernameBtn, #submitUsernameBtn:hover, #submitTokenBtn, #submitTokenBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#backBtn, #backBtn:hover {
		color: #632163 !important;
		border-color: #632163 !important;
		background-color: white !important;
	}

	.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: red !important;
}

.custome-step .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: red !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
//    background-color: #632163 !important;
   color: #ffffff;
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: #632163 !important;
    background: #632163 !important;
}
</style>
